import { createGlobalStyle } from 'styled-components'
// // Regular - 400
import Digital7Woff from './fonts/Digital-7Mono.woff'
import Digital7Woff2 from './fonts/Digital-7Mono.woff2'

const Digital7 = {
  regular: {
    woff2: Digital7Woff2,
    woff: Digital7Woff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Digital7.regular.woff2}) format('woff2'),
    url(${Digital7.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
