import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import photo from 'img/photo.jpg'
import toDate from 'date-fns/toDate'
import intervalToDuration from 'date-fns/intervalToDuration'
import { zonedTimeToUtc } from 'date-fns-tz'
import isPast from 'date-fns/isPast'
import isBefore from 'date-fns/isBefore'

const END_TIMER_DATE = 1672776000

interface TimerType {
  days: string
  hours: string
  minutes: string
  seconds: string
}

function App() {
  const [isDone, setIsDone] = useState(false)
  const [timer, setTimer] = useState<TimerType>()
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>()
  useEffect(() => {
    let endDate = toDate(END_TIMER_DATE * 1000)
    const now = zonedTimeToUtc(Date.now(), 'UTC')
    endDate = zonedTimeToUtc(endDate, 'UTC')
    if (isBefore(endDate, now)) {
      setTimer({
        seconds: '00',
        minutes: '00',
        hours: '00',
        days: '00',
      })
      setIsDone(true)
      return
    }
    setTimerInterval(
      setInterval(() => {
        const now = zonedTimeToUtc(Date.now(), 'UTC')
        if (isBefore(endDate, now)) {
          setIsDone(true)
          clearInterval(timerInterval)
          return
        }
        const duration = intervalToDuration({
          start: now,
          end: endDate,
        })
        if (
          duration.days === undefined ||
          duration.hours === undefined ||
          duration.minutes === undefined ||
          duration.seconds === undefined
        )
          return
        const formattedTimer: TimerType = {
          days:
            duration.days < 10 ? '0' + duration.days : duration.days.toString(),
          hours:
            duration.hours < 10
              ? '0' + duration.hours
              : duration.hours.toString(),
          minutes:
            duration.minutes < 10
              ? '0' + duration.minutes
              : duration.minutes.toString(),
          seconds:
            duration.seconds < 10
              ? '0' + duration.seconds
              : duration.seconds.toString(),
        }
        setTimer(formattedTimer)
      }, 1000)
    )
  }, [])
  return (
    <Root>
      <Content>
        <Image>{isDone && <a href="/opacha.pdf">What's in the box?</a>}</Image>
        <Timer>
          {timer?.days}:{timer?.hours}:{timer?.minutes}:{timer?.seconds}
        </Timer>
      </Content>
    </Root>
  )
}

export default App

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  padding: 20px;
`
const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 360px;
  background: url(${photo}) no-repeat center;
  background-size: cover;
  a {
    display: block;
    background: #fff;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding: 10px 25px;
    font-family: sans-serif;
    color: #1b1b1b;
  }
`

const Timer = styled.div`
  font-size: 59px;
  /* line-height: 32px; */
  padding-top: 20px;
  text-align: center;
  color: #343434;
`
