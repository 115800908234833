import styled, { createGlobalStyle } from 'styled-components'
import mountain from 'img/mountains.png'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    scroll-behavior: smooth;
    height: 100%;
    min-height: 100%;
  }

  body {
    font-family: 'Aeroport';
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    background: url(${mountain}) no-repeat top center #F2F3F6;
    background-size: cover;
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }

  input {
    background: none;
    border-radius: 8px;
    outline: none;
  }

  textarea {
    outline: none;
  }

  a {
    text-decoration: none;
  }
  p {
    margin: 0;
  }
  tr, th, td, table {
  }
`

export default GlobalStyle
